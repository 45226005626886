import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import querystring from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { signUp } from "../../../lib/analytics";
import getAPIEndpoint from "../../../lib/getAPIEndpoint";
import { getUserState, login } from "../../../redux/slices/userSlice";
import Spinner from "../../atoms/Spinner/Spinner";

const LoginPage = () => {
  const router = useRouter();
  const { isLoadingUser, user, error } = useSelector(getUserState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const next = router.query.next as string;
  const dispatch = useDispatch();

  const sendRequest = useCallback(() => {
    dispatch(login({ email, password }));
  }, [email, password]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (next) {
      router.push(next);
    }
    router.push("/");
  }, [user, next]);

  return (
    <div className="min-h-screen mx-auto container flex justify-center items-center flex-col">
      <h1 className="text-4xl font-medium mb-12 mt-4 text-center">
        Log in to Shopirise
      </h1>
      <div className="bg-white shadow-md w-full md:w-96 rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col">
        <div className="-mt-12 mx-auto flex justify-center items-center bg-white h-24 w-24 rounded-full ring-2 ring-gray-200 z-10">
          <Image
            src="/images/logo-square.svg"
            width="48"
            height="48"
            alt="Logo"
          />
        </div>
        <div className="flex items-center justify-center mb-4 mt-8">
          <a
            href={`${getAPIEndpoint()}/auth/google?${querystring.stringify(
              router.query
            )}`}
            onClick={() => signUp({ source: "login" })}
          >
            <Image
              width={191}
              height={46}
              src="/images/google_sign_in.png"
              alt="Log in with Google"
            />
          </a>
        </div>
        <div className="flex justify-center items-center w-100 mb-4">
          <hr className="flex-1" />
          <span className="my-0 mx-4 text-gray-400 text-sm">or</span>
          <hr className="flex-1" />
        </div>
        <div className="mb-4">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className="block text-grey-darker text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-700"
            type="text"
            id="email"
            name="email"
            placeholder="joe@appleseed.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className="block text-grey-darker text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******************"
          />
          {error ?? (
            <span className="text-red-500 text-sm italic">{error}</span>
          )}
        </div>
        <div className="flex items-center justify-center mb-2">
          <button
            className="flex justify-center bg-blue-500 hover:bg-blue-700 w-48 text-white font-bold py-2 px-4 rounded"
            type="button"
            disabled={isLoadingUser}
            onClick={sendRequest}
          >
            {isLoadingUser ? <Spinner /> : <span>Log In</span>}
          </button>
        </div>
        <div className="flex flex-col mt-4">
          <p className="text-xs mb-2">
            If you don't have an account,&nbsp;
            <Link href="/signup">
              <a className="text-blue-500 hover:text-blue-800">go create one</a>
            </Link>
          </p>
          <p className="text-xs mb-2">
            If you forgot your password,&nbsp;
            <Link href="/reset-password">
              <a className="text-blue-500 hover:text-blue-800">reset it here</a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
